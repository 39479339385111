import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';

const Contact = () => {
    return (
        <>
            <SEO title="Contacto || MISS PARAGUAY" />
          
            <HeaderTwo
        btnStyle="btn-small round btn-icon"
        HeaderSTyle="header-transparent"
      /><br/><br/>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                        <div className="inner text-center">
                  
 <h1 className="title theme-gradient display-two">Contacto</h1>
 </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
                <FooterTwo/>
        </>
    )
}
export default Contact;