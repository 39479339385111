import React from 'react'
import { Link } from 'react-router-dom';

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                {/* <li><a href="#">Policias de privacidad</a></li> */}
                                <li><Link to="/bases-condiciones.pdf">Términos y condiciones</Link></li>
                                {/* <li><a href="/contact">Contacto</a></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-md-end">
                            <a href='https://dareagenciadigital.com/'><p className="copyright-text">© Dare Agencia Digital {new Date().getFullYear()}</p></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Copyright;