import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><a href="/#nosotros">Nosotros</a></li>
            <li><a href="/#directivos">Directivos</a></li>
            <li><a href="/#eventos">Eventos</a></li>
            <li><a href="/#galeria">Galería</a></li>
            <li><a href="/#auspiciantes">Auspiciantes</a></li>
            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSe8Lh2UOg3TgUJ2SQjVB7dHeavUPTMZiSW-2siO6ynCY6yinA/viewform">Inscribite Aquí</a></li>
            <li><Link to="/contacto">Contactanos</Link></li>
        </ul>
    )
}
export default Nav;
