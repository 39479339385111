import React from 'react';
import {Link} from "react-router-dom";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import Copyright from './Copyright';
import Whatsapp from '../../components/pageToTop/contactos/Whatsapp'


const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFour =  footerOne[4];
const footerIntemFive =  footerOne[5];


const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);
const callToActionData = {
    title: "Ready to start creating a standard website?",
    subtitle: "If you want to create a corporate template you can purshace now our doob template.",
    btnText: "Purchase Doob",
}
const FooterTwo = () => {
    return (
        <>
 
            {/* <footer className="rn-footer footer-style-default variation-two">
            <div className="rn-callto-action clltoaction-style-default style-7">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8 col-md-8">
                        <div className="inner">
                            <div className="content text-start">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                />
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p className="subtitle">{callToActionData.subtitle}</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                    <div className="rn-footer-widget">
                                   
                                    <div className="inner">
                                        <h6 className="subtitle">{footerIntemFive.subtitle}</h6>
                                        <br/>
                                        <ul className="social-icon social-default justify-content-start">
                                            <li><Link to="facebook.com"><FiFacebook /></Link></li>
                                            <li><Link to="twitter.com"><FiTwitter /></Link></li>
                                            <li><Link to="instagram.com"><FiInstagram /></Link></li>
                                            <li><Link to="linkdin.com"><FiLinkedin /></Link></li>
                                        </ul>
                                    
                                </div>
                            
                    </div>
                </div>
            </div>
        </div> </div>
            </footer> */}
            <Copyright/>
            <Whatsapp/>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
