import React from 'react';

const BrandList = [
    {
        image: './images/brand/monalisa.png',
        link:'https://www.monalisa.com.py/'
    }
]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href={data.link}><img src={`${data.image}`} alt="departamentos del país" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
