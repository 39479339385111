import React, {useState} from 'react';
import {FiChevronUp} from "react-icons/fi";
import Blury from '../../../common/Blury';
import logo from '../../../assets/images/whatsapp.png'
import { Link } from 'react-router-dom';


const Whatsapp = () => {
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
    };
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    };
    window.addEventListener('scroll', toggleVisible);

    return (
        <>
        <a href ="https://api.whatsapp.com/send?phone=595981208810" target="_blank">
            <div 
            className="rn-back-top2" 
            onClick={scrollToTop}
            style={{display: visible ? 'inline' : 'none'}}
            > <img src={logo} alt="Blog Image" style={{width:"8rem"}} /></div>
            {/* <Blury /> */}
            </a>
        </>
    )
}

export default Whatsapp;