import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin, FiPhone } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>


            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone/>
                                        
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Nro. de contacto</h4>
                                        <p><a href="tel:+444 555 666 777">+595 981 208810</a></p>
                                    </div>
                                </div>
                          

                          
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                        
                                    </div>
                                    <div className="inner">
                                    <h4 className="title">E-mail</h4>
                                        <p><a href="mailto:admin@gmail.com">organizacionmissparaguaysa@gmail.com</a></p>
                                        
                                    </div>
                                </div>
                     

                            
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                        
                                    </div>
                                    <div className="inner">
                                    <h4 className="title">Dirección</h4>
                                        <p>San Martín 259 - Cuarto Barrio - Luque, Paraguay</p>
                                    </div>
                                </div>
                            </div>
                </div>
            
        </>
    )
}
export default ContactOne;