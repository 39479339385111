import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiFacebook, FiInstagram, FiLinkedin, FiTwitter, FiYoutube } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Logo from '../logo/Logo';

const callToActionData = {
    title: "Ready to start creating a standard website?",
    subtitle: "Finest choice for your home & office",
    btnText: "Purchase Doob",
}

const CalltoActionTwo = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/prueba2-08.png)`, backgroundPosition:"center"}} data-black-overlay="8">
            <div className="container">
                <div className="row row--15 align-items-center content-wrapper">
                    <div className="col-lg-6 col-md-6">
                        <div className='imgausc'>
                            
                                <img className='imgaus'
                                    src={`${process.env.PUBLIC_URL}/images/logo/logos-o.png`}
                                    
                                />
                          
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6">
                    <div className="rn-footer-widget">
                                   
                                    <div className="inner">
                                        <br/><br/>
                                        <h6 className="subtitle">¡Seguinos en nuestras RRSS!</h6>
                                        <br/>
                                        <ul className="social-icon social-default imgausc2">
                                            <li><a href="https://www.facebook.com/MissParaguay"><FiFacebook/></a></li>
                                            <li><a href="https://www.instagram.com/miss_paraguay/"><FiInstagram/></a></li>
                                            <li><a href="https://www.youtube.com/@asistentemisspy"><FiYoutube/></a></li>
                                        </ul>
                                    
                                </div>
                            
                    </div>
                </div>
            </div>
            </div>
        
        </div>
    )
}
export default CalltoActionTwo;